@use '@/styles/utils/mixins.scss' as *;

.categoryWrapper {
  scroll-margin-top: 7.5rem;
}

.productWrapper {
  .sectionHeadingContainer {
    display: flex;
    gap: 2.5rem;
    justify-content: space-between;
    @include tab() {
      flex-direction: column;
    }
  }
  .sectionDescription {
    max-width: 29rem;
  }
  .productCards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    margin-top: 3.75rem;

    &Less {
      grid-template-columns: repeat(2, 1fr);

      @include mob-tab() {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    @include tab() {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mob() {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
